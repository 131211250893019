import React from "react";
import Daniel from "./images/Daniel-Vollmond-blau.jpg";

function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        gap: "32px",
      }}
    >
      <div>
        <img
          src={Daniel}
          style={{
            height: "100vh",
          }}
        />
      </div>
      <div
        style={{
          marginTop: "32px",
        }}
      >
        Willkommen bei aumannpress
        <br />
        <br />
        Diese Seite wird gerade neue gestaltet
        <br />
        <br />
        <hr />
        <br />
        info@aumannpress.de
        <br />
        +49 175 7724400
      </div>
    </div>
  );
}

export default App;
